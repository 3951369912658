<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(product, index) in productsData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="product.productNameCurrent"
      :description="product.fullCode"
      :imagePath="product.productImagePath"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <button
          :title="$t('info')"
          @click="
            setProductData(product);
            openBottomSheet('ProductInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setProductData(product);
            openBottomSheet('ProductQRCode');
          "
          :title="$t('general.qrCode')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasProductEdit())">
        <button
          @click="
            setProductData(product);
            openBottomSheet('ProductUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasProductFinaleDelete())">
        <button
          v-b-modal.ProductDelete
          :title="$t('delete')"
          @click="setProductData(product)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasProductChangeActivationType())">
        <button
          v-b-modal.ProductChangeActivationType
          :title="$t('changeActivationType')"
          @click="setProductData(product)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setProductData(product);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../../utils/functions";
import {
  hasProductEdit,
  hasProductFinaleDelete,
  hasProductChangeActivationType,
} from "./../../../../../utils/privilegeHelper";
import CustomCard from "./../../../../../components/general/CustomCard.vue";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["productsData", "filterData", "defaultImg"],
  methods: {
    setProductData(product) {
      this.$emit("setProductData", product);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasProductEdit,
    hasProductFinaleDelete,
    hasProductChangeActivationType,
  },
};
</script>
