import GeneralModel from "./../../../general/GeneralModel";
import Product from "./Product";
import ProductsFilter from "./ProductsFilter";

export default class Products extends GeneralModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setGeneralModelInitialValue();
    this.productsData = [];
    this.product = new Product();
    this.filterData = new ProductsFilter();
  }
  fillData(data) {
    if (data) {
      this.fillGeneralModelData(data);
      this.productsData = data.productsData || [];
      let filter = { ...this.filterData, ...data.pagination };
      this.filterData.fillData(filter);
    } else {
      this.setInitialValue();
    }
  }
}
