<template>
  <CustomBottomSheet
    :refName="'ProductInfo'"
    size="xl"
    :headerText="$t('Products.data')"
    :headerIcon="product.icon"
  >
    <div class="row">
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.fullCode"
        :title="$t('Products.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productNameAr"
        :title="$t('Products.nameAr')"
        :imgName="'products.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productNameEn"
        :title="$t('Products.nameEn')"
        :imgName="'products.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productNameUnd"
        :title="$t('Products.nameUnd')"
        :imgName="'products.svg'"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productDescriptionAr"
        :title="$t('Products.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productDescriptionEn"
        :title="$t('Products.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productDescriptionUnd"
        :title="$t('Products.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="product.productPriceWithCurrency"
        :title="$t('price')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="
          product.categoryInfoData
            ? product.categoryInfoData.categoryNameCurrent
            : ''
        "
        :title="$t('Categories.name')"
        :imgName="'categories.svg'"
      />
    </div>
    <div class="form-actions">
      <div @click.prevent="closeBottomSheet('ProductInfo')" class="icon-cancel">
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../../components/general/CustomBottomSheet.vue";
import DataLabelGroup from "./../../../../../components/general/DataLabelGroup.vue";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    DataLabelGroup,
  },
  props: ["product"],
};
</script>
