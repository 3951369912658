export default class CategoryInfoData {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.categoryToken = "";
    this.categoryNameCurrent = "";
    this.categoryNameAr = "";
    this.categoryNameEn = "";
    this.categoryNameUnd = "";
    this.categoryDescriptionCurrent = "";
    this.categoryDescriptionAr = "";
    this.categoryDescriptionEn = "";
    this.categoryDescriptionUnd = "";
    this.categoryNotes = "";
    this.categoryImageIsDefault = false;
    this.categoryImagePath = "";
    this.categoryImageSizeBytes = 0;
    this.categoryImageSizeTextCurrent = "";
    this.totalCountProducts = 0;
    this.totalCountProductsActive = 0;
    this.totalCountProductsBlock = 0;
  }
  fillData(data) {
    if (data) {
      this.categoryToken = data.categoryToken ?? "";
      this.categoryNameCurrent = data.categoryNameCurrent ?? "";
      this.categoryNameAr = data.categoryNameAr ?? "";
      this.categoryNameEn = data.categoryNameEn ?? "";
      this.categoryNameUnd = data.categoryNameUnd ?? "";
      this.categoryDescriptionCurrent = data.categoryDescriptionCurrent ?? "";
      this.categoryDescriptionAr = data.categoryDescriptionAr ?? "";
      this.categoryDescriptionEn = data.categoryDescriptionEn ?? "";
      this.categoryDescriptionUnd = data.categoryDescriptionUnd ?? "";
      this.categoryNotes = data.categoryNotes ?? "";
      this.categoryImageIsDefault = data.categoryImageIsDefault ?? false;
      this.categoryImagePath = data.categoryImagePath ?? "";
      this.categoryImageSizeBytes = data.categoryImageSizeBytes ?? 0;
      this.categoryImageSizeTextCurrent =
        data.categoryImageSizeTextCurrent ?? "";
      this.totalCountProducts = data.totalCountProducts ?? 0;
      this.totalCountProductsActive = data.totalCountProductsActive ?? 0;
      this.totalCountProductsBlock = data.totalCountProductsBlock ?? 0;
    } else {
      this.setInitialValue();
    }
  }
}
