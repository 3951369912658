<template>
  <div class="">
    <DashboardNavbar
      v-on:updateViewType="updateViewType('products', $event)"
      :viewType="generalSetting.products.viewType"
      :viewTypeStatus="true"
      v-on:updateActivationTypeTokens="changeActivationType($event)"
      :activationTypeTokens="generalSetting.products.activationTypeTokens"
      :activationTypesTokensOptions="activationTypesTokensOptions"
      :activationTypeStatus="true"
      :btnAddTitle="$t('Products.add')"
      :btnAddName="'ProductAdd'"
      :btnAddStatus="checkPrivilege(hasProductAdd())"
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <StoreItemsTabs />
      <template v-if="hasData">
        <ProductTable
          class="animate__animated animate__bounceIn"
          v-if="generalSetting.products.viewType == VIEW_TYPES.table"
          v-on:setProductData="products.product.fillData($event)"
          :productsData="products.productsData"
          :defaultImg="products.product.defaultImg"
          :filterData="products.filterData"
        />

        <ProductCards
          class="animate__animated animate__bounceIn"
          v-else-if="generalSetting.products.viewType == VIEW_TYPES.cards"
          v-on:setProductData="products.product.fillData($event)"
          :productsData="products.productsData"
          :defaultImg="products.product.defaultImg"
          :filterData="products.filterData"
        />

        <ActionsData :actionsData="products.product" />
        <ProductInfo :product="products.product" />
        <ProductDelete
          :product="products.product"
          v-on:refresh="getAllProducts()"
        />
        <ProductChangeActivationType
          :product="products.product"
          v-on:refresh="getAllProducts()"
        />
        <CustomBottomSheetQRCode
          :refName="'ProductQRCode'"
          :code="products.product.fullCode"
          :codeTitle="$t('Products.code')"
          :name="products.product.productNameCurrent"
          :nameTitle="$t('Products.name')"
          :nameIcon="'products.svg'"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="products.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <ProductFilter
        :theFilterData="products.filterData"
        v-on:search="search($event)"
      />

      <ProductAdd :product="products.product" v-on:refresh="getAllProducts()" />
      <ProductUpdate
        :product="products.product"
        v-on:refresh="getAllProducts()"
      />
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../../../utils/constants";
import { ACTIVATION_TYPE } from "./../../../../utils/constantLists";
import DashboardNavbar from "./../../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../../components/general/ExceptionWithImg.vue";
import ActionsData from "./../../../../components/general/ActionsData.vue";
import CustomBottomSheetQRCode from "./../../../../components/general/CustomBottomSheetQRCode.vue";
import CustomPagination from "./../../../../components/general/CustomPagination.vue";
import StoreItemsTabs from "./../storeItems/StoreItemsTabs.vue";
import ProductAdd from "./components/ProductAdd.vue";
import ProductUpdate from "./components/ProductUpdate.vue";
import ProductCards from "./components/ProductCards.vue";
import ProductTable from "./components/ProductTable.vue";
import ProductInfo from "./components/ProductInfo.vue";
import ProductDelete from "./components/ProductDelete.vue";
import ProductChangeActivationType from "./components/ProductChangeActivationType.vue";
import ProductFilter from "./components/ProductFilter.vue";
import Products from "./../../../../models/settings/settingsOthers/products/Products";
import apiProduct from "./../../../../api/settings/settingsOthers/products";
import { getDialogOfActivationTypes } from "./../../../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../../../utils/generalMixin";
import generalSettingsMixin from "./../../../../utils/generalSettingsMixin";
import { checkPrivilege } from "./../../../../utils/functions";
import {
  hasProductAdd,
  hasProductViewActive,
  hasProductViewBlocked,
} from "./../../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CustomBottomSheetQRCode,
    CustomPagination,
    StoreItemsTabs,
    ProductAdd,
    ProductUpdate,
    ProductCards,
    ProductTable,
    ProductInfo,
    ProductDelete,
    ProductChangeActivationType,
    ProductFilter,
  },
  computed: {
    hasData() {
      return this.products.productsData.length != 0;
    },
    activationTypesTokensOptions: function () {
      let arr = [];
      if (checkPrivilege(hasProductViewActive())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Active
        );
        obj.totalNumber =
          this.products.activationStatistics.totalActiveCount || 0;
        if (obj) arr.push(obj);
      }

      if (checkPrivilege(hasProductViewBlocked())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Blocked
        );
        obj.totalNumber =
          this.products.activationStatistics.totalBlockedCount || 0;
        if (obj) arr.push(obj);
      }
      return arr;
    },
  },
  data() {
    return {
      products: new Products(),
      VIEW_TYPES,
      activationTypesTokenOptions: getDialogOfActivationTypes(),
    };
  },
  methods: {
    checkPrivilege,
    hasProductAdd,
    changePagination(pagination) {
      this.products.filterData.fillData(pagination);
      this.getAllProducts();
    },
    changeActivationType(data) {
      this.updateActivationTypeTokens("products", data);
      this.products.filterData.activationTypeTokens = data;
      this.getAllProducts();
    },
    search(data) {
      this.products.filterData.fillData(data);
      this.getAllProducts();
    },
    async getAllProducts() {
      this.isLoading = true;
      try {
        this.products.productsData = [];
        const response = await apiProduct.getAll(this.products.filterData);
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.products.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllProducts();
  },
};
</script>
