import BaseEntityModel from "./../../../general/BaseEntityModel";
import defaultImg from "@/assets/images/products.svg";
import CategoryInfoData from "./../categories/CategoryInfoData";

export default class Product extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setInitialValueBaseEntityModel();
    this.mediaFile = "";
    this.productToken = "";
    this.productNameCurrent = "";
    this.productNameAr = "";
    this.productNameEn = "";
    this.productNameUnd = "";
    this.productDescriptionCurrent = "";
    this.productDescriptionAr = "";
    this.productDescriptionEn = "";
    this.productDescriptionUnd = "";
    this.productNotes = "";
    this.productImageIsDefault = false;
    this.productImagePath = "";
    this.productImageSizeBytes = 0;
    this.productImageSizeTextCurrent = "";
    this.productPrice = "";
    this.productPriceText = "";
    this.productPriceWithCurrency = "";
    this.categoryToken = "";
    this.categoryInfoData = new CategoryInfoData();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.mediaFile = "";
      this.productToken = data.productToken ?? "";
      this.productNameCurrent = data.productNameCurrent ?? "";
      this.productNameAr = data.productNameAr ?? "";
      this.productNameEn = data.productNameEn ?? "";
      this.productNameUnd = data.productNameUnd ?? "";
      this.productDescriptionCurrent = data.productDescriptionCurrent ?? "";
      this.productDescriptionAr = data.productDescriptionAr ?? "";
      this.productDescriptionEn = data.productDescriptionEn ?? "";
      this.productDescriptionUnd = data.productDescriptionUnd ?? "";
      this.productNotes = data.productNotes ?? "";
      this.productImageIsDefault = data.productImageIsDefault ?? false;
      this.productImagePath = data.productImagePath ?? "";
      this.productImageSizeBytes = data.productImageSizeBytes ?? 0;
      this.productImageSizeTextCurrent = data.productImageSizeTextCurrent ?? "";
      this.productPrice = data.productPrice ?? "";
      this.productPriceText = data.productPriceText ?? "";
      this.productPriceWithCurrency = data.productPriceWithCurrency ?? "";
      this.categoryToken = data.categoryToken ?? "";
      this.categoryInfoData.fillData(data.categoryInfoData);
    } else {
      this.setInitialValue();
    }
  }
}
